import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import Card from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { accountRefs } from "@/app/account.refs.mjs";
import { SubscriberButton } from "@/feature-subscriber/Shared.jsx";
import getHextechRankIcon from "@/game-lol/utils/get-rank-icon.mjs";
import hextechRoleJungle from "@/game-lol/utils/get-role-icon.mjs";
import BlitzLock from "@/inline-assets/blitz-lock.svg";
import BlitzCaretDown from "@/inline-assets/caret-down.svg";
import BlitzCaretUp from "@/inline-assets/caret-up.svg";
import { winRateColors } from "@/util/constants.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const Header = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--sp-13);
  padding: 0 var(--sp-5);
  border-bottom: var(--sp-px) solid var(--shade6);

  svg {
    font-size: var(--sp-5);
    color: var(--shade2);
  }

  .header-icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
      width: var(--sp-6);
      height: var(--sp-6);
    }
    .mr-8 {
      margin-right: var(--sp-2);
    }
  }

  .avatar-icon {
    width: var(--sp-6);
    height: var(--sp-5_5);
    margin-right: var(--sp-2);
    background: var(--shade6);
    border-radius: 0 var(--br-sm) var(--br-sm) 0;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: var(--sp-5);
      height: var(--sp-5);
    }
  }
`;
const Group = styled("div")`
  padding: var(--sp-6);
  > div:last-child {
    > div:last-child {
      margin-bottom: 0;
    }
  }
  .item-desc {
    color: var(--shade2);
  }
  .subscriber-locked-bg {
    position: relative;
  }
  .subscriber-locked {
    position: absolute;
    background: var(--shade7-75);
    backdrop-filter: blur(5px);
    width: 107%;
    height: 100%;
    top: 0;
    left: -10px;
    .lock-icon {
      position: absolute;
      top: 40%;
      left: 45%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: var(--sp-8);
      height: var(--sp-8);
      background: hsla(var(--subscriber-solid-hsl) / 0.15);
      color: var(--subscriber-solid);
      border-radius: var(--br-sm);
    }
  }
  .pro-btn {
    background: var(--subscriber-gradient);
    color: var(--shade0);
    margin-top: var(--sp-5);
    width: 100%;
  }
  .mt-4_5 {
    margin-top: var(--sp-4_5);
  }
`;
const Item = styled("div")`
  margin-bottom: var(--sp-2_5);
  .grouped-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .value-tag {
      display: flex;
      align-items: center;
      & > p {
        margin-right: var(--sp-2);
      }
    }
  }
  & > p:nth-child(2) {
    margin-top: var(--sp-1);
  }
`;

const Tag = styled("div")`
  display: flex;
  align-items: center;
  width: var(--sp-15);
  height: var(--sp-6);
  padding: var(--sp-px) var(--sp-2) var(--sp-px) var(--sp-1_5);
  border-radius: var(--br-sm);

  svg {
    width: var(--sp-4);
    height: var(--sp-4);
  }
  p {
    flex-grow: 1;
    text-align: center;
    max-width: calc(100% - 16px);
  }

  &.positive {
    background-color: rgba(48, 217, 212, 0.15);
    color: var(--turq);
  }
  &.negative {
    background-color: hsla(var(--red-hsl) / 0.15);
    color: ${winRateColors.wrBinaryBad};
  }
`;

const Avatar = styled("div")`
  & > img {
    width: var(--sp-6);
    border-radius: var(--br-sm) 0 0 var(--br-sm);
  }
`;

const NAME_MAP = {
  1: ["lol:jungleMonsters.blueBuff", "Blue Buff"], // ORDER
  2: ["lol:jungleMonsters.wolves", "Wolves"], // ORDER
  3: ["lol:jungleMonsters.raptors", "Raptors"], // ORDER
  4: ["lol:jungleMonsters.redBuff", "Red Buff"], // ORDER
  5: ["lol:jungleMonsters.krugs", "Krugs"], // ORDER
  6: ["lol:jungleMonsters.dragon", "Dragon"],
  7: ["lol:jungleMonsters.blueBuff", "Blue Buff"], // CHAOS
  8: ["lol:jungleMonsters.wolves", "Wolves"], // CHAOS
  9: ["lol:jungleMonsters.raptors", "Raptors"], // CHAOS
  10: ["lol:jungleMonsters.redBuff", "Red Buff"], // CHAOS
  11: ["lol:jungleMonsters.krugs", "Krugs"], // CHAOS
  12: ["lol:jungleMonsters.baronNashor", "Baron Nashor"],
  13: ["lol:jungleMonsters.gromp", "Gromp"], // ORDER
  14: ["lol:jungleMonsters.gromp", "Gromp"], // CHAOS
  15: ["lol:jungleMonsters.botSideScuttle", "Bot side Scuttle"],
  16: ["lol:jungleMonsters.topSideScuttle", "Top side Scuttle"],
  17: ["lol:jungleMonsters.riftHerald", "Rift Herald"],
};
const getName = (id, t) => {
  const [path, fb] = NAME_MAP[id];
  return t(path, fb);
};
const TIME_DELTAS = {
  CHALLENGER: {
    delta: -0.5,
    long: -1.5,
  },
  GRANDMASTER: {
    delta: -1.5,
    long: -1,
  },
  MASTER: {
    delta: -0.5,
  },
  PLATINUM: {
    delta: 0.5,
  },
  GOLD: {
    delta: 1.5,
  },
  SILVER: {
    delta: 2.5,
  },
  BRONZE: {
    delta: 3.5,
  },
  IRON: {
    delta: 3.5,
    long: 4.5,
  },
};

const mapTimesToRank = (rank, points) => {
  if (rank) rank = rank.toUpperCase().replace("+", "");
  if (!rank || !rank.length || rank === "DIAMOND") return points;
  const mapping = TIME_DELTAS[rank];
  if (!mapping) return points;
  const isLongRoute = points.length > 3;
  const targetMapping =
    isLongRoute && mapping.long !== undefined ? mapping.long : mapping.delta;
  return points.map((point, index) => {
    if (index === 0) return point;
    return {
      ...point,
      expected_time: Math.round(point.expected_time + targetMapping),
    };
  });
};

const getJunglePathExpected = (data, champion, side, rank) => {
  if (!data || !champion || !side) return null;
  const t = side === 100 ? "blue" : "red";
  if (!data[champion]) return null;
  return mapTimesToRank(rank, data[champion][t]);
};

const formatDurationNormal = (time) => {
  const minutes = `${Math.floor(time / 60)}`;
  const minutesFormatted = minutes.length === 1 ? `0${minutes}` : minutes;
  const seconds = `${Math.floor(time % 60)}`;
  const secondsFormatted = seconds.length === 1 ? `0${seconds}` : seconds;
  return `${minutesFormatted}:${secondsFormatted}`;
};

const PostMatchJunglePathing = ({
  //Note(Liz3): named this raw to not confuse with the later "data", raw is simply the users match data renamed/
  data: raw,
  champion,
  team,
  championImage,
}) => {
  const { t } = useTranslation();
  const state = useSnapshot(readState);
  const { activeSubscriber } = useSnapshot(accountRefs);
  // @ts-expect-error - This doesn't exist. Fix this.
  const junglePaths = state.lol.junglePaths;
  const compareElo =
    // @ts-expect-error - This doesn't exist. Fix this.
    state.settings?.lol?.overlays?.csBenchmarks?.leagueDivision || "GOLD";
  const compareEloUpperCase = compareElo.toUpperCase();
  /* eslint-disable react-hooks/exhaustive-deps */
  const md = useMemo(() => {
    if (!raw || !Array.isArray(raw.data) || !champion) return null;
    const expectedCamps = getJunglePathExpected(
      junglePaths,
      champion,
      team,
      compareEloUpperCase,
    );
    const data = raw.data.filter((entry) => entry.state === 2);
    if (!expectedCamps) return null;
    const mapped = [];
    let last;
    for (let i = 0; i < expectedCamps.length; i++) {
      const currentExpected = expectedCamps[i];
      if (!data[i]) {
        mapped.push({ ...currentExpected, done: false });
      } else {
        const doneEntry = data[i];
        const isRightCamp = doneEntry.index === currentExpected.index;
        const diff = doneEntry.done - currentExpected.expected_time;
        const slower = diff > 0;
        const normalized = diff < 0 ? -diff : diff;
        const entry = {
          index: currentExpected.index,
          doneIndex: doneEntry.index,
          isRightCamp,
          done: isRightCamp,
          diff: normalized,
          slower,
          doneTime: doneEntry.done,
          expected: currentExpected.expected_time,
        };
        mapped.push(entry);
        last = entry;
      }
    }

    return { mapped, total: expectedCamps.length, last };
  }, [raw, junglePaths]);

  if (!md) return null;
  const { mapped: mappedData, total: totalCamps, last } = md;
  if (!last) return null;
  const RankIcon = getHextechRankIcon(compareEloUpperCase);
  const placeHolder = "--";
  const playerRole = raw.role ? raw.role.toLowerCase() : "jungle";
  const RoleIcon = hextechRoleJungle(playerRole);

  // TODO: i18n - part of LoL, better duration localization
  return (
    <Card padding="0">
      <Header>
        <p className="type-body2">
          {t("common:junglePathing", "Jungle Pathing")}
        </p>
        <div className="header-icons">
          <Avatar>
            <img src={championImage} />
          </Avatar>
          <div className="avatar-icon">
            <RoleIcon />
          </div>
          <p className="type-caption--bold mr-8">{t("lol:vs", "vs")}</p>
          <RankIcon />
        </div>
      </Header>
      <Group>
        <Item>
          <div className="grouped-items">
            <p className="type-caption--bold">
              {t("lol:junglePath.clear", "{{value}} Camps clear", {
                value: totalCamps,
              })}
            </p>
            <div className="value-tag">
              <p className="type-caption--bold color-shade1">
                {formatDurationNormal(last.doneTime)}
              </p>
              <Tag className={last.slower ? "negative" : "positive"}>
                {last.slower ? <BlitzCaretDown /> : <BlitzCaretUp />}
                <p className="type-caption--semi">
                  {t("common:time.secondsWithS", {
                    defaultValue: "{{value}}s",
                    value: last.diff.toFixed(0),
                  })}
                </p>
              </Tag>
            </div>
          </div>
          <p className="type-caption item-desc">
            {t(
              "common:junglePathing.itemDescription",
              "This is the recommended jungle path for this champion based on the meta.",
            )}
          </p>
        </Item>
        <div className="subscriber-locked-bg">
          {mappedData.map((entry, index) => {
            return (
              <Item key={index}>
                <div className="grouped-items">
                  <p className="type-caption--bold">
                    {entry.done
                      ? getName(entry.doneIndex, t)
                      : `- (${getName(entry.index, t)})`}
                  </p>
                  <div className="value-tag">
                    <p className="type-caption--bold color-shade1">
                      {entry.done
                        ? formatDurationNormal(entry.doneTime)
                        : placeHolder}
                    </p>
                    <Tag
                      className={
                        entry.slower || !entry.isRightCamp
                          ? "negative"
                          : "positive"
                      }
                    >
                      {entry.slower ? <BlitzCaretDown /> : <BlitzCaretUp />}
                      <p className="type-caption--semi">
                        {entry.done
                          ? t("common:time.secondsWithS", {
                              defaultValue: "{{value}}s",
                              value: entry.diff.toFixed(0),
                            })
                          : placeHolder}
                      </p>
                    </Tag>
                  </div>
                </div>
              </Item>
            );
          })}
          {!activeSubscriber && (
            <div className="subscriber-locked">
              <div className="lock-icon">
                <BlitzLock />
              </div>
            </div>
          )}
        </div>
        {!activeSubscriber && (
          <div className="mt-4_5">
            <SubscriberButton size="medium" href="/premium">
              {t("common:wallet.unlockWithPremium", "Unlock with Premium")}
            </SubscriberButton>
          </div>
        )}
      </Group>
    </Card>
  );
};

export default PostMatchJunglePathing;
